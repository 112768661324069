<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Forwarder <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.vendorName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.paymentDate }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.voucherNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="12" md="8" lg="8">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.paymentMethod }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6"> </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="form.accounts">
          <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
            <account-list :items="form.accounts" type="detail"></account-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charge</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ formatPrice(form.bankCharge) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.remark }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab>
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6" class="pb-0"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Invoice <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.invoices"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.invoices.map(x => x.referenceId).indexOf(item.referenceId) + 1 }}
                        </template>
                        <template v-slot:item.totalAmountAfterTax="{ item }">
                          {{ formatPrice(item.totalAmountAfterTax) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalInvoiceIDR="{ item }">
                          {{ formatPrice(item.totalInvoiceIDR) }}
                        </template>
                        <template v-slot:item.invoicePaid="{ item }">
                          {{ formatPrice(item.invoicePaid) }}
                        </template>
                        <template v-slot:item.totalInvoicePaidIDR="{ item }">
                          {{ formatPrice(item.totalInvoicePaidIDR) }}
                        </template>
                        <template v-slot:item.fullPayment="{ item }">
                          {{ item.fullPayment ? "YES" : "NO" }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import AccountList from "@/components/AccountList";

export default {
  name: "detail-purchase-other-payment",
  components: {
    "account-list": AccountList,
  },
  props: {
    form: Object,
    id: Number,
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice #",
        value: "invoiceNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Date",
        value: "invoiceDate",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "totalAmountAfterTax",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Invoice (RP)",
        value: "totalInvoiceIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Paid",
        value: "invoicePaid",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Paid (RP)",
        value: "totalInvoicePaidIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Full Payment",
        value: "fullPayment",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
    ],
  }),

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
